import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']
  static values = { 
    uploadUrl: String, 
    tableResizeBars: { type: Boolean, default: true }  
  }

  initialize() {
    this.defaults = {
      base_url: '/tinymce',
      language: 'uk',
      images_upload_url: this.uploadUrlValue,
      images_upload_credentials: true,
      images_upload_base_path: window.location.origin + '/',
      relative_urls : false,
      remove_script_host : false,
      document_base_url: window.location.origin + '/',
      plugins: 'anchor autolink charmap codesample image link lists media searchreplace table visualblocks wordcount code',
      toolbar: 'undo redo | formatselect fontselect fontsizeselect | bold italic underline strikethrough | link image media table | addcomment showcomments | spellcheckdialog a11ycheck | align lineheight | numlist bullist indent outdent | charmap | removeformat',
      tinycomments_mode: 'embedded',
      tinycomments_author: 'Author name',
      fontsize_formats: '6.5pt 7pt 8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
      height: 700,
      table_resize_bars: this.tableResizeBarsValue
    }
  }

  connect() {
    // Initialize the app
    let config = Object.assign({ target: this.inputTarget }, this.defaults)
    tinymce.init(config)
  }

  disconnect() {
    tinymce.remove()
  }
}

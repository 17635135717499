import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="waybill-form"
export default class extends Controller {
  static targets = [ 
    "id",
    "truck", 
    "departureAt", 
    "departureOdometer",
    "departureFuelLevel",
    "arrivalAt", 
    "arrivalOdometer",
    "arrivalFuelLevel",
    "departureHint"
   ];

  connect() {
    $(this.truckTarget).on('select2:select', () => { 
      this.refresh();
     })
  }

  refresh() {
    if (this.idTarget.value) {
      return;
    }

    const params = { 
      truck_id: this.truck,
      arrival_at: this.arrivalAt,
      departure_at: this.departureAt
    };

    fetch(`/waybills/prepare.json?` + new URLSearchParams(params).toString()).then((response) => {
			response.json().then((data) => {
        if (data.last_waybill) {
          this.departureOdometerTarget.value = data.departure_odometer;
          this.departureFuelLevelTarget.value = data.departure_fuel_level;
        
          this.departureHintTargets.forEach(element => {
            element.innerHTML = data.last_waybill.hint;
            element.classList.remove('d-none');
          });
        } else {
          this.departureHintTargets.forEach(element => {
            this.departureHintTarget.classList.add('d-none');
          });
        }

        this.arrivalOdometerTarget.value = data.arrival_odometer;
      })
    })
  }

  get truck() {
		return this.truckTarget.value
	}

  get departureAt() {
    return this.departureAtTarget.value
  }

  get arrivalAt() {
    return this.arrivalAtTarget.value
  }
}

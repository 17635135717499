import { Controller } from "@hotwired/stimulus"
import Sortable from "stimulus-sortable"

// Connects to data-controller="nested-sortable"
export default class extends Sortable {
  static targets = [ "position" ];

  // You can override the `onUpdate` method here.
  onUpdate({ item, newIndex }) {
    this.positionTargets.forEach((element, index) => {
      element.value = index + 1
    });
  }
}
